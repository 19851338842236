.posts {
    width: 100%;
    height: 70vh;
    @media screen and (min-width: 767px){
        height: 75vh;
    }
    overflow: hidden;
    // overflow-y: scroll;
    // overflow-x: hidden;

    > div > div > div {
        display: flex;
    }
}

.posts__filters {
    padding: 1rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 767px){
        padding: 2rem;
        margin-bottom: 2rem;
    }
    background: $grey;

    button {
        border: 0;
        background: transparent;

        &:hover{
            cursor: pointer;
        }
    }
}

.loop__post {
    padding: 0 1em;
    min-width: 100%;
    min-width: 340px;

    @media (min-width: 767px){
        min-width: 50vw;
    }

    @media (min-width: 1024px){
        min-width: 33vw;
    }

    @media (min-width: 1680px){
        min-width: 25vw;
    }

    width: auto;
    max-width: 100%;

    &--txt{
        line-height: 1.75;
        font-size: 1.25rem;
        max-width: 90vw;

        @media (min-width: 767px){
            max-width: 600px;
        }

        @media (min-width: 1024px){
            max-width: 750px;
        }
    }

    &--desc {
        font-size: .85rem;
        height: 75vh;
        overflow: auto;
        padding-left: 40px;

        @media (min-width: 767px){ 
            font-size: 1rem;
            padding-left: 0;
        }

        @media (min-width: 1680px){
            font-size: 1.2rem;

        }
    }

    figure {
        height: 48vh;
        @media (min-width: 767px){
            height: 55vh;
        }
        text-align: center;

        img {
            width: 100%;
            width: auto;
            min-height: 100%;
            height: 100%;
        }
    }

    .post__title {
        font-size: 1.25em;
        margin: 1em 0 .75em;
        max-width: 90vw;
    }
    
    .post__excerpt {
        line-height: 1.5;
        max-width: 90vw;

        @media (min-width: 767px){
            max-width: 50vw;
        }
    
        @media (min-width: 1024px){
            max-width: 33vw;
        }
    
        @media (min-width: 1680px){
            max-width: 25vw;
        }

        p {
            margin: 0;
        }
    }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 999;
    background: rgba(#000,.5);
    border: 0;
    color: #fff;
    text-align: center;
    padding: .75rem 0;
    width: 125px;
    display: block;

    &:hover {
        transition: background-color 300ms ease-in-out;
        background-color: $primary_acc;
        cursor: pointer;
    }

    &.slick-prev {
        transform-origin: left;
        transform: rotate(-90deg);
        left: 19px;
    }
    &.slick-next{
        transform-origin: right;
        transform: rotate(-90deg);
        right: 19px;
        margin-top: -90px;
    }
}