.main-nav {
    position: relative;
}

.menu { 
    list-style: none outside none;
    margin: 0;
    padding: 1.5rem 1rem;

    > li {
        display: inline-block;
        vertical-align: middle;
        padding: 1em;

        a {
            display: inline-block;
        }
    }
}

@media screen and (max-width: 767px){
    .main-nav {
        position: fixed;
        top: -100%; 
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 9999;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all 300ms ease-in-out;

        &--open {
            opacity: 1;
            top: 0;
        }
    }

    .menu__toggle {
        display: block;
        width: 25px;
        position: absolute;
        // right: 15px;
        right: 16px;
        top: 26px;
        z-index: 99999;

        &:hover {
            cursor: pointer;
        }
    
        > span{
            display: block;
            height: 2px;
            margin-bottom: 4px;
            width: 100%;
            background: #000;
            transition: all 300ms ease-in-out;
        }
    
        @media screen and (min-width: 768px){
            display: none;
        }
    
        &--open {
            > span {
                background-color: #000;
    
                &:nth-child(1){
                    transform: rotate(45deg);
                    position: relative;
                    top: 6px;
                }
    
                &:nth-child(2){
                    display: none;
                }
    
                &:nth-child(3){
                    transform: rotate(-45deg);
                }
            }
        }
    }
}