body {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    color: $base;
    overflow: hidden;
}

*, *:before, *:after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
    margin-top: 0;
    font-weight: 400;
}

h1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin: 1.5rem 0;
    // color: #3e0b06;

    @media screen and (min-width: 767px){
        font-size: 2rem;
        margin: 2rem;
    }
    @media screen and (min-width: 1280px){
        font-size: 2.5rem;
        margin: 2rem 0 2rem;
    }
    @media screen and (min-width: 1920px){
        font-size: 3rem;
        margin: 2rem 0 3rem;
    }
}

h2 {
    font-weight: 300;
    font-size: 1.15rem;

    @media screen and (min-width: 400px){
        font-size: 1.25rem;
    }
    @media screen and (min-width: 767px){
        font-size: 1.5rem;
    }
    @media screen and (min-width: 1280px){
        font-size: 2rem;
    }
}

figure {
    margin: 0;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-center {
    align-items: center;
}

.flex-jcenter {
    justify-content: center;
}

.flex-abs-center {
    align-items: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.lgrey-bg {
    background: $light_grey;
}

a {
    color: $base;
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 2px solid $primary_acc;

    &:hover {
        border-bottom-color: $base;
    }
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 15px;
  }
  
  .right {
    transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
  }

  .main-wrapper {
      height: 75vh;
      overflow: hidden;
  }

  .is-home {
    height: 90vh;

    .main-wrapper {
        height: 90vh;
    }

    .global__footer {
        display: none;
    }
  }