.categories {
    .category {
        @media screen and (min-width: 767px){
            margin: 0 2rem;
        }
    }
}

.category__wrap{
    height: 45vh;
    margin-bottom: 1rem;
    transition: all 300ms ease-in-out;
    -webkit-transition : -webkit-filter 300ms linear;

    &--art {
        background: url('../img/cat-art.png') no-repeat 50% 50%;
        background-size: contain;
        scale: 0.75;

        @media screen and (min-width: 767px) { 
            left: 15%;
            margin-left: -4rem;
            margin-top: 3rem;
        }

        @media screen and (min-width: 1200px) {
            margin-left: -10rem;
         }
    }

    &--skate {
        background: url('../img/cat-skate.png') no-repeat 50% 50%;
        background-size: contain;
        margin-top: 1rem;

        @media screen and (min-width: 767px) { 
            right: 15%;
            margin-right: -4rem;
            margin-top: 4rem;
        }
        
        @media screen and (min-width: 1200px) {
          //  margin-left: -10rem;
        }
    }

    &:hover {
         opacity: 0.9;  
         filter: invert(1); 
    }
}

.page {
    height: 90vh;
    overflow: auto;

    &--loop {
        overflow: hidden;
    }
}

.home {
    background-size: cover;
    background: url('../img/Billy_gee_site_collage_1920x1080.jpg') no-repeat 50% 25%;
    height: 100vh;

    @media screen and (min-width: 767px) { 
        background-position: 50% 50%;
        background-size: cover;
    }
}

.text-hidden {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    clip:rect(0,0,0,0);
}

.single__content {
    width: 100%;
    max-width: 60em;
    margin: 0 auto;
    padding: 0 1rem;

    @media screen and (min-width: 767px) {
        padding: 0 2rem;
    }
    
    iframe, video {
        min-width: 100%;
        width: 100%;
    }

    img {
        min-width: 100%;
        max-width: 100%;
    }
}

.page--bio {
    .single__content {
        @media screen and (min-width: 767px) {
            font-size: 1.5rem;
        }
        width: 100%;
        max-width: 800px;
        overflow: hidden;
        // margin: 0;
    }
}

.single {
    overflow: auto;
    height: 85vh;
    width: 100%;

    .single__header{
        width: 100%;
        position: relative;
    }

    .single__scroll {
        z-index: 99;
        position: absolute;
        bottom: 3rem;
        left: 50%;
        margin-left: -15px;
        font-size: .75rem;
        font-weight: 700;

        span {
            display: block;
            font-size: .75rem;
            font-weight: 700;
            line-height: 2;
        }
    }

    .single__image {
        height: 85vh;
        width: 100%;
        background: $grey;
        margin: 0 auto 2rem;

        img {
            width: auto;
            max-height: 100%;
            max-width: 100%;
            margin: 0 0 1em;
        }
    }

    .single__wrapper {
        @media screen and (min-width: 767px) {
            font-size: 1.25rem;
        }
        line-height: 1.75;

        img {
            max-width: 100%;
        }
    }

    .single__cta {
        margin-bottom: 5rem;
    }
}