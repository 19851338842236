.global__header {
    height: 15vh;

    .logo {
        transition: all 300ms ease-in-out;
        padding: 0 1rem;
        position: relative;
        z-index: 99999;
        height: 60px;

        @media screen and (min-width: 767px){
            height: 80px;
        }
        img {
            transition: all 300ms ease-in-out;
            height: 60px;

            @media screen and (min-width: 767px){
            height: 80px;
            }
        }
        
        a{
            border: 0;
        }
    }
    .instalink {
        display: none;
        @media screen and (min-width: 767px) {
            display: block;
        }
        > span {
            font-size: 1.5rem;
            display: inline-block;
            vertical-align: middle;
            margin-left: .5rem;
            position: relative;
            top: -2px;
        }
        border: 0;

        margin-left: auto;
        margin-right: 2rem;
    }
}

.is-home {
    .global__header {
        height: 10vh;

        .logo {
            @media screen and (min-width: 767px){
                height: 40px;
            }

            img {
                height: 60px;
    
                @media screen and (min-width: 767px){
                    height: 40px;
                }
            }
        }
    }
}