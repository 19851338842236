.animated-wrapper {
    animation: 0.45s pulse normal;
    position: relative;
}


@keyframes pulse {
    0% {
      opacity: 0;
      top: 75px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
}
  


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  .bounce {
    animation: bounce 2s infinite;
  }

  .bounce--down {
    animation: bounceDown 2s infinite;
  }


  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  @keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0) rotate(42deg);
    }
    40% {
      transform: translateY(-15px) rotate(47deg);
    }
    60% {
      transform: translateY(-10px) rotate(45deg);
    }
  }
