.row {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    &--sm {
        max-width: 800px;
    }

    &--md {
        max-width: 1200px;
    }
}


.col {
    width: 100%;
    padding: 0 1rem;
    
    @media screen and (min-width: 767px){
        padding: 0 2rem;
    }
}

.sm-50{
    width: 50%;
}

@media screen and (min-width: 767px){
    .md-50 {
        width: 50%;
    }

    .md-33 {
        width: 33.333%;
    }
}